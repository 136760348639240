import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Wave from '../components/wave';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Contact Me`}</h1>
    <p>{`You can get in touch with me.`}</p>
    <h2>{`Or you can wave to me`}</h2>
    <p>{`Just click this button to simulate a friendly wave.`}</p>
    <Wave mdxType="Wave" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      